
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '~/tailwind.config.js'
import page from '~/mixins/page'
import pageTransition from '~/mixins/pageTransition'

export default {
  mixins: [page, pageTransition],

  provide() {
    return {
      pageId: this.page.id,
    }
  },

  computed: {
    pageArea() {
      return this.page?.page_categories?.area?.value
    },

    // define the color theme for this site
    pageColorStyle() {
      const twColorConfig = resolveConfig(tailwindConfig)?.theme?.colors
      if (!twColorConfig || !this.pageArea) {
        return '' // default is red
      }
      let color = ''
      let hoverColor = ''
      switch (this.pageArea) {
        case 'default':
          return
        case 'sustainability':
          color = twColorConfig.turquoise.DEFAULT
          hoverColor = twColorConfig.turquoise.dark
          break
        case 'wellness':
          color = twColorConfig.blue.DEFAULT
          hoverColor = twColorConfig.blue.dark
          break
        case 'apartments':
          color = twColorConfig.violet.DEFAULT
          hoverColor = twColorConfig.violet.dark
          break
        case 'family':
          color = twColorConfig.red.DEFAULT
          hoverColor = twColorConfig.red.dark
          break
        case 'quality':
          color = twColorConfig.blue.dark
          hoverColor = twColorConfig.blue.darker
          break
        default:
          break
      }

      return {
        '--page-color': color,
        '--page-hover': hoverColor,
      }
    },
  },
}
